import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";

function DeleteModal({ isOpen, content, onClose, onConfirm, isLoading }) {
  const cancelRef = useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={!isLoading && onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Hapus {content}
          </AlertDialogHeader>

          <AlertDialogBody>
            Apakah anda yakin untuk menghapus {content}?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button size={"sm"} ref={cancelRef} onClick={onClose}>
              Batal
            </Button>
            <Button
              size={"sm"}
              colorScheme="red"
              isLoading={isLoading}
              onClick={onConfirm}
              ml={3}
            >
              Hapus
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DeleteModal;
