import { StepsTheme as Steps } from "chakra-ui-steps";

const theme = {
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  components: {
    Steps,
  },
  initialColorMode: "light",
  useSystemColorMode: false,
};

export default theme;
